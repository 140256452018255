import React from 'react'
import { Box } from '@mui/material'

import Layout from '../components/layout'

export default function EmptyPage({pageContext}){
    return (
    <Layout>
        <Box sx={{p:3}}>
            <h1>{pageContext.title}</h1>
            {/* <div>{parse(pageContext.content)}</div> */}
            <div dangerouslySetInnerHTML={{ __html: pageContext.content }}></div>
        </Box>
    </Layout>
    )
}